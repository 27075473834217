import React, { Component } from "react";
import axios from "axios";
import { Form, Button, Header, TextArea, Input } from "semantic-ui-react";
import "./App.css";
import CONFIG from "./config.json";

class App extends Component {
  state = {
    name: "",
    message: "",
    email: "",
    sent: false,
    buttonText: "Send Message",
  };

  formSubmit = (e) => {
    e.preventDefault();

    this.setState({
      buttonText: "...sending",
    });

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      subject: CONFIG.appName,
    };

    let url =
      process.env.NODE_ENV === "development"
        ? CONFIG.TEST_FUNCTION
        : "https://us-central1-findmory-photoframe.cloudfunctions.net/httpToEmail";
    axios
      .post(url, data)
      .then((res) => {
        this.setState({ sent: true }, this.resetForm());
      })
      .catch(() => {
        console.log("Message not sent");
      });
  };

  resetForm = () => {
    this.setState({
      name: "",
      message: "",
      email: "",
      buttonText: "Message Sent",
    });
  };

  render() {
    return (
      <Form className="contact-form" onSubmit={(e) => this.formSubmit(e)}>
        <Header className="message">
          Send us your message about the {CONFIG.appName} App
        </Header>
        <TextArea
          onChange={(e) => this.setState({ message: e.target.value })}
          name="message"
          className="message-input"
          type="text"
          placeholder="Please write your message here"
          value={this.state.message}
          required
        />

        <Header className="message-name" htmlFor="message-name">
          Your Name
        </Header>
        <Input
          onChange={(e) => this.setState({ name: e.target.value })}
          name="name"
          className="message-name"
          type="text"
          placeholder="Your Name"
          value={this.state.name}
        />

        <Header className="message-email" htmlFor="message-email">
          Your Email
        </Header>
        <Input
          onChange={(e) => this.setState({ email: e.target.value })}
          name="email"
          className="message-email"
          type="email"
          placeholder="your@email.com"
          required
          value={this.state.email}
        />

        <div className="button--container">
          <Button positive>{this.state.buttonText}</Button>
        </div>
      </Form>
    );
  }
}

export default App;
